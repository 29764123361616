.item-line{
    display: flex;
}
.my-spinner{
    width: 1.5rem;
    height: 1.5rem;
}
.my-container{
    margin: 30px 5px;
}
.my-version{
    display: none;
}
.my-code{
    margin: 10px 5px;
    background-color: black;
    color: aqua;
    min-height: 500px;
}

@media (max-width:425px) {
    .my-code{
        min-height: 200px;
    }
    .my-version{
        display: block;
        margin-top: 5px;
        color: #FFF;
        font-size: 12px;
    }
}